.html5 {
  width: 65%;
  -moz-animation: html5 2s ease;
  -webkit-animation: html5 2s ease;
}

.node-js {
  width: 60%;
  -moz-animation: node-js 2s ease;
  -webkit-animation: node-js 2s ease;
}
.csharp {
  width: 90%;
  -moz-animation: Csharp 2s ease;
  -webkit-animation: Csharp 2s ease;
}
.html5 {
  width: 50%;
  -moz-animation: html5 2s ease;
  -webkit-animation: html5 2s ease;
}
.react-js {
  width: 85%;
  -moz-animation: react-js 2s ease;
  -webkit-animation: react-js 2s ease;
}
.rabbitmq {
  width: 60%;
  -moz-animation: rabbitmq 2s ease;
  -webkit-animation: rabbitmq 2s ease;
}
.vue {
  width: 45%;
  -moz-animation: vue 2s ease;
  -webkit-animation: vue 2s ease;
}
.net-core {
  width: 75%;
  -moz-animation: net-core 2s ease;
  -webkit-animation: net-core 2s ease;
}
.net {
  width: 80%;
  -moz-animation: net 2s ease;
  -webkit-animation: net 2s ease;
}
.sql {
  width: 67%;
  -moz-animation: sql 2s ease;
  -webkit-animation: sql 2s ease;
}
.angularjs {
  width: 60%;
  -moz-animation: angularjs 2s ease;
  -webkit-animation: angularjs 2s ease;
}
.angular-7 {
  width: 50%;
  -moz-animation: angular-7 2s ease;
  -webkit-animation: angular-7 2s ease;
}
.mongodb {
  width: 50%;
  -moz-animation: mongodb 2s ease;
  -webkit-animation: mongodb 2s ease;
}
.ef {
  width: 60%;
  -moz-animation: ef 2s ease;
  -webkit-animation: ef 2s ease;
}
.android {
  width: 40%;
  -moz-animation: android 2s ease;
  -webkit-animation: android 2s ease;
}
.swift {
  width: 10%;
  -moz-animation: swift 2s ease;
  -webkit-animation: swift 2s ease;
}
.aws {
  width: 40%;
  -moz-animation: aws 2s ease;
  -webkit-animation: aws 2s ease;
}
.xamarin {
  width: 68%;
  -moz-animation: xamarin 2s ease;
  -webkit-animation: xamarin 2s ease;
}
.react-native {
  width: 12%;
  -moz-animation: react-native 2s ease;
  -webkit-animation: react-native 2s ease;
}
.flutter {
  width: 22%;
  -moz-animation: flutter 2s ease;
  -webkit-animation: flutter 2s ease;
}
.kubernetes {
  width: 62%;
  -moz-animation: kubernetes 2s ease;
  -webkit-animation: kubernetes 2s ease;
}
.docker {
  width: 80%;
  -moz-animation: docker 2s ease;
  -webkit-animation: docker 2s ease;
}
.jenkins {
  width: 72%;
  -moz-animation: jenkins 2s ease;
  -webkit-animation: jenkins 2s ease;
}
.typescript {
  width: 62%;
  -moz-animation: typescript 2s ease;
  -webkit-animation: typescript 2s ease;
}
